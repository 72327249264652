import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchInvoices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          // .get('/apps/invoice/invoices', { params: queryParams })
          .get('/invoices', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchInvoice(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          // .get(`/apps/invoice/invoices/${id}`)
          .get(`invoice/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    processPayment(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/invoice/pay', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    checkPayment(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/invoice/payment/status', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    downloadInvoice(ctx, id) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/invoice/${id}/download`,
          responseType: 'arraybuffer',
          method: 'GET',
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    saveChequeDetails(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/invoice/${data.invoice_id}/cheque/save`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    processCardPayment(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .post('/invoice/process/card-payment', id)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getJambopayToken(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('invoice/jambopay/token', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    jambopaySuccessCallback(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('invoice/jambopay/success/callback', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    jambopayFailedCallback(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('invoice/jambopay/failed/callback', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
