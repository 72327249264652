import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'

export default function useInvoicesList() {
  // Use toast
  const toast = useToast()

  const refInvoiceListTable = ref(null)

  const refEnterPhoneNumberForm = ref(null)

  const refSelectPaymentMethodForm = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'invoice_id', label: '#' },
    { key: 'entity' },
    { key: 'status' },
    { key: 'total', formatter: val => `Ksh. ${val}` },
    { key: 'issuedDate' },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalInvoices = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const showPaymentModal = ref(false)
  const paymentInvoiceId = ref('')
  const paymentPhoneNumber = ref('')

  const checkPaymentModal = ref(false)
  const checkPaymentInvoiceId = ref('')

  const selectedPaymentMethod = ref('')

  const invoiceData = ref(null)

  const isLoading = ref(false)

  const chequeNumber = ref('')
  const bankCode = ref('')
  const accountNumber = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refInvoiceListTable.value
      ? refInvoiceListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoices.value,
    }
  })

  const refetchData = () => {
    refInvoiceListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const fetchInvoices = (ctx, callback) => {
    store
      .dispatch('invoice/fetchInvoices', {
        q: searchQuery.value,
        per_page: perPage.value,
        page: currentPage.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { data: invoices, total } = response.data.data

        callback(invoices.filter(invoice => invoice.licenses.length > 0 || invoice.psv_licenses.length > 0))
        totalInvoices.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching invoices' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const selectPaymentMethod = () => {
    refSelectPaymentMethodForm.value.validate().then(success => {
      if (success) {
        isLoading.value = true
        switch (selectedPaymentMethod.value) {
          case 'M-Pesa':
            router.replace({
              name: 'invoice-pay',
              params: { id: paymentInvoiceId.value },
            })
            break
          case 'Cheque':
            router.replace({
              name: 'invoice-cheque-view',
              params: { id: paymentInvoiceId.value },
            })
            break
          case 'Debit/Credit Card':
            // router.replace({name: 'invoice-jambopay-view', params: { id: paymentInvoiceId.value}})
            store
              .dispatch('invoice/getJambopayToken', {
                invoice_id: paymentInvoiceId.value,
              })
              .then(response => {
                if (response.data.success) {
                  const checkoutDetails = {
                    OrderId: response.data.invoice.invoice_id,
                    CustomerEmail: response.data.invoice.user.email,
                    Currency: 'KES',
                    CustomerPhone: response.data.invoice.user.phone_number,
                    OrderAmount: response.data.amount,
                    BusinessEmail: 'martin@deveint.com',
                    CancelledUrl: '/jambopay/transaction/cancel',
                    CallBackUrl: '/jambopay/success/callback',
                    Description: 'Checkout description',
                    SupportPhone: '0727429744',
                    StoreName: 'PRISK',
                    ClientKey: response.data.client_key,
                  }

                  jambopayCheckout(checkoutDetails, jambopayCallback)
                }
              })
              .catch(error => {
                console.log(error)
              })
              .finally(() => {
                isLoading.value = false
              })
            break
          default:
            break
        }
      }
    })
  }

  const jambopayCallback = data => {
    if (data.status === 'SUCCESS') {
      store
        .dispatch('invoice/jambopaySuccessCallback', {
          invoice_id: data.order_id,
          receipt: data.receipt,
          amount: data.amount,
        })
        .then(() => {
          router.replace({
            name: 'invoice-view',
            params: { id: paymentInvoiceId.value },
          })
          toast({
            component: ToastificationContent,
            props: {
              title: 'Transaction successfull',
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
    } else if (data.status === 'Failed') {
      store
        .dispatch('invoice/jambopayFailedCallback', {
          invoice_id: paymentInvoiceId.value,
        })
        .then(response => {
          router.replace({
            name: 'invoice-view',
            params: { id: response.data.data.invoice_id },
          })
          toast({
            component: ToastificationContent,
            props: {
              title: 'Transaction failed or was cancelled',
              icon: 'AlertTraingleIcon',
              variant: 'danger',
            },
          })
        })
    } else {
      store
        .dispatch('invoice/jambopayFailedCallback', {
          invoice_id: paymentInvoiceId.value,
        })
        .then(response => {
          router.replace({
            name: 'invoice-view',
            params: { id: response.data.data.invoice_id },
          })
          toast({
            component: ToastificationContent,
            props: {
              title: 'Transaction failed or was cancelled',
              icon: 'AlertTraingleIcon',
              variant: 'danger',
            },
          })
        })
    }
  }

  const saveChequeDetails = () => {
    isLoading.value = true
    store
      .dispatch('invoice/saveChequeDetails', {
        invoice_id: paymentInvoiceId.value,
        cheque_number: chequeNumber.value,
        bank_code: bankCode.value,
        account_number: accountNumber.value,
      })
      .then(() => {
        router.replace({
          name: 'invoice-view',
          params: { id: paymentInvoiceId.value },
        })
        toast({
          component: ToastificationContent,
          props: {
            title: 'Details updated successfully',
            icon: 'CoffeeIcon',
            variant: 'success',
          },
        })
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error saving cheque details',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  const processPayment = () => {
    refEnterPhoneNumberForm.value.validate().then(success => {
      if (success) {
        isLoading.value = true
        store
          .dispatch('invoice/processPayment', {
            invoice_id: paymentInvoiceId.value,
            phone_number: paymentPhoneNumber.value,
          })
          .then(response => {
            router.push({
              name: 'invoice-pay-confirm',
              params: { id: paymentInvoiceId.value },
            })
            toast({
              component: ToastificationContent,
              props: {
                title: response.data.data,
                icon: 'CoffeeIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            toast({
              component: ToastificationContent,
              props: {
                title: error.response.data.data,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
          .finally(() => {
            isLoading.value = false
          })
      }
    })
  }

  const checkPayment = invoiceId => {
    isLoading.value = true
    store
      .dispatch('invoice/checkPayment', {
        invoice_id: invoiceId,
      })
      .then(response => {
        router.push({ name: 'invoice-view', params: { id: invoiceId } })
        toast({
          component: ToastificationContent,
          props: {
            title: response.data.message,
            icon: 'CoffeeIcon',
            variant: 'success',
          },
        })
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  const resolveInvoiceStatusVariantAndIcon = status => {
    if (status === 'Paid') return 'success'
    if (status === 'Pending') return 'secondary'
    return 'danger'
  }

  const getAmountPaid = invoice => {
    let amount = 0
    invoice.payments.forEach(payment => {
      amount += Number(payment.amount)
    })

    return amount
  }

  const getInvoiceBalance = (invoice, totalAmount) => {
    let amount = 0
    invoice.payments.forEach(payment => {
      amount += Number(payment.amount)
    })

    return totalAmount - amount
  }

  const getDiscountedAmount = invoice => {
    let totalAmount = 0
    let discountedPrice = 0
    if (invoice.licenses.length) {
      invoice.licenses.forEach(license => {
        if (license.metadata !== null) {
          let rate = 0
          const result = Object.keys(JSON.parse(license.metadata)).map(key => [key, JSON.parse(license.metadata)[key]])
          for (const data in result[0][1]) {
            totalAmount += Number(result[0][1][data])

            if (Number(result[0][1][data] != 0)) {
              rate = license.tariff.tariff_category.rates.find(rate => (rate.rated_item === data)).cumulative_rate
            }
          }
          // eslint-disable-next-line operator-assignment
          totalAmount = rate * totalAmount
          if (totalAmount < license.tariff.price) {
            totalAmount = license.tariff.price
          }
        } else {
          totalAmount += license.tariff.price
        }
      })
    } else {
      invoice.psv_licenses.forEach(license => {
        totalAmount += license.tariff.price
      })
    }

    discountedPrice = totalAmount - ((invoice.discount / 100) * totalAmount)

    return discountedPrice
  }

  const getInvoiceTotalAmount = invoice => {
    let totalAmount = 0
    if (invoice.licenses.length) {
      invoice.licenses.forEach(license => {
        if (license.metadata !== null) {
          let rate = 0
          const result = Object.keys(JSON.parse(license.metadata)).map(key => [key, JSON.parse(license.metadata)[key]])
          for (const data in result[0][1]) {
            totalAmount += Number(result[0][1][data])

            if (Number(result[0][1][data] != 0)) {
              rate = license.tariff.tariff_category.rates.find(rate => (rate.rated_item === data)).cumulative_rate
            }
          }
          // eslint-disable-next-line operator-assignment
          totalAmount = rate * totalAmount
        } else {
          totalAmount += license.tariff.price
        }
      })
    } else {
      invoice.psv_licenses.forEach(license => {
        totalAmount += license.tariff.price
      })
    }

    return totalAmount
  }

  return {
    fetchInvoices,
    tableColumns,
    perPage,
    currentPage,
    totalInvoices,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refInvoiceListTable,

    statusFilter,

    resolveInvoiceStatusVariantAndIcon,

    refEnterPhoneNumberForm,
    refSelectPaymentMethodForm,

    isLoading,

    refetchData,

    invoiceData,

    showPaymentModal,
    paymentInvoiceId,
    paymentPhoneNumber,
    processPayment,

    checkPaymentModal,
    checkPaymentInvoiceId,
    checkPayment,

    selectedPaymentMethod,
    selectPaymentMethod,

    saveChequeDetails,
    chequeNumber,
    bankCode,
    accountNumber,

    getAmountPaid,
    getInvoiceBalance,
    getDiscountedAmount,
    getInvoiceTotalAmount,
  }
}
