<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="mb-1 mb-md-0"
        >
          <label>Show</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <label>Entries</label>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <b-row>
            <b-col
              lg="7"
              sm="12"
            >
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </b-col>
            <b-col
              lg="5"
              sm="12"
            >
              <v-select
                v-model="statusFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                class="invoice-filter-select"
                placeholder="Select Status"
              >
                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>
              </v-select>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>

    <b-table
      ref="refInvoiceListTable"
      :items="fetchInvoices"
      responsive
      :fields="tableColumns"
      primary-key="invoice_id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative font-weight-bolder"
    >
      <!-- Column: Id -->
      <template #cell(invoice_id)="data">
        <b-link
          :to="{ name: 'invoice-view', params: { id: data.item.invoice_id }}"
          class="font-weight-bold"
        >
          #{{ data.item.invoice_id }}
        </b-link>
      </template>

      <!-- Column: Entity -->
      <template #cell(entity)="data">
        <span
          v-if="data.item.licenses.length"
          class="text-nowrap"
        >
          {{ data.item.licenses[0].business_name }}
        </span>
        <span
          v-else-if="data.item.psv_licenses.length"
          class="text-nowrap"
        >
          {{ data.item.psv_licenses[0].sacco_name }}
        </span>
        <span
          v-else
          class="text-nowrap"
        />
      </template>

      <!-- Column: Invoice Status -->
      <template #cell(status)="data">
        <b-badge :variant="resolveInvoiceStatusVariantAndIcon(data.item.status)">
          {{ data.item.status }}
        </b-badge>
      </template>

      <!-- Total -->
      <template #cell(total)="data">
        <span v-if="data.item.discount != 0">
          Ksh.{{ new Intl.NumberFormat().format(getDiscountedAmount(data.item)) }}({{ data.item.discount }}% disc.)
        </span>
        <span v-else>
          Ksh.{{ new Intl.NumberFormat().format(getInvoiceTotalAmount(data.item)) }}
        </span>
      </template>

      <!-- Column: Issued Date -->
      <template #cell(issuedDate)="data">
        <span class="text-nowrap">
          {{ moment(data.item.created_at).format("D-M-YYYY") }}
        </span>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <b-button
            size="sm"
            @click="printInvoice(data.item)"
          >
            Download
          </b-button>
          <b-button
            :to="{ name: 'invoice-view', params: { id: data.item.invoice_id }}"
            variant="warning"
            size="sm"
            class="ml-1"
          >
            View
          </b-button>
        </div>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalInvoices"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BPagination,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useInvoicesList from './useInvoiceList'

import invoiceStoreModule from './invoiceStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BPagination,

    vSelect,
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    // Use toast
    const toast = useToast()

    const statusOptions = [
      'Paid',
      'Pending',
    ]

    const getDiscountedAmount = invoice => {
      let totalAmount = 0
      let discountedPrice = 0
      if (invoice.licenses.length) {
        invoice.licenses.forEach(license => {
          if (license.metadata !== null) {
            let rate = 0
            const result = Object.keys(JSON.parse(license.metadata)).map(key => [key, JSON.parse(license.metadata)[key]])
            for (const data in result[0][1]) {
              totalAmount += Number(result[0][1][data])

              if (Number(result[0][1][data] !== 0)) {
                rate = license.tariff.tariff_category.rates.find(rate => (rate.rated_item === data)).cumulative_rate
              }
            }
            totalAmount = rate * totalAmount
          } else {
            totalAmount += license.tariff.price
          }
        })
      } else {
        invoice.psv_licenses.forEach(license => {
          totalAmount += license.tariff.price
        })
      }

      discountedPrice = totalAmount - ((invoice.discount / 100) * totalAmount)

      return discountedPrice
    }

    const getInvoiceTotalAmount = invoice => {
      let totalAmount = 0
      if (invoice.licenses.length) {
        invoice.licenses.forEach(license => {
          if (license.metadata !== null) {
            let rate = 0
            const result = Object.keys(JSON.parse(license.metadata)).map(key => [key, JSON.parse(license.metadata)[key]])
            for (const data in result[0][1]) {
              totalAmount += Number(result[0][1][data])

              if (Number(result[0][1][data] != 0)) {
                rate = license.tariff.tariff_category.rates.find(rate => (rate.rated_item === data)).cumulative_rate
              }
            }
            totalAmount = rate * totalAmount
          } else {
            totalAmount += license.tariff.price
          }
        })
      } else {
        invoice.psv_licenses.forEach(license => {
          totalAmount += license.tariff.price
        })
      }

      return totalAmount
    }

    const printInvoice = invoice => {
      store
        .dispatch('invoice/downloadInvoice', invoice.invoice_id)
        .then(response => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]))
          const fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute(
            'download',
            `${invoice.invoice_id}.pdf`,
          )
          document.body.appendChild(fileLink)

          fileLink.click()
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error accessing file. Please try again.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,

      refetchData,

      resolveInvoiceStatusVariantAndIcon,
    } = useInvoicesList()

    return {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,

      refetchData,
      getInvoiceTotalAmount,
      getDiscountedAmount,

      statusOptions,

      avatarText,
      resolveInvoiceStatusVariantAndIcon,

      printInvoice,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
